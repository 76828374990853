import React from "react";
import classes from "./Loader.module.scss";

const Loader = (props) => {
  return (
    <div
      className={
        props.hide === true
          ? [classes.preloader, classes.hide].join(" ")
          : classes.preloader
      }
    >
      <div className={classes.loader}></div>
    </div>
  );
};

export default Loader;
